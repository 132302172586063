export const solventOptions = [{
    label: 'Acetic acid',
    value: 'Acetic acid'
  }, {
    label: 'Acetone',
    value: 'Acetone'
  }, {
    label: 'Acetonitrile',
    value: 'Acetonitrile'
  }, {
    label: 'Benzene',
    value: 'Benzene'
  }, {
    label: 'Butanol',
    value: 'Butanol'
  }, {
    label: 'Carbon tetrachloride (CCl4)',
    value: 'Carbon tetrachloride'
  }, {
    label: 'Chloroform',
    value: 'Chloroform'
  }, {
    label: 'Cyclohexane',
    value: 'Cyclohexane'
  }, {
    label: 'Diethyl ether',
    value: 'Diethyl ether'
  }, {
    label: 'Dimethyl sulfoxide (DMSO)',
    value: 'Dimethyl sulfoxide'
  }, {
    label: 'Dimethylformamide (DMF)',
    value: 'Dimethylformamide'
  }, {
    label: '1,4-Dioxane',
    value: '1,4-Dioxane'
  }, {
    label: 'Ethanol',
    value: 'Ethanol'
  }, {
    label: 'Ethyl acetate',
    value: 'Ethyl acetate'
  }, {
    label: 'Isopropanol',
    value: 'Isopropanol'
  }, {
    label: 'Methanol',
    value: 'Methanol'
  }, {
    label: 'Methylene chloride (DCM)',
    value: 'Methylene chloride'
  }, {
    label: 'Methyl tert-butyl ether (MTBE)',
    value: 'Methyl tert-butyl ether'
  }, {
    label: 'n-Hexane',
    value: 'n-Hexane'
  }, {
    label: 'N-Methyl-2-pyrrolidone (NMP)',
    value: 'N-Methyl-2-pyrrolidone'
  }, {
    label: 'Pentane',
    value: 'Pentane'
  }, {
    label: 'Pyridine',
    value: 'Pyridine'
  }, {
    label: 'Tetrahydrofuran (THF)',
    value: 'Tetrahydrofuran'
  }, {
    label: 'Toluene',
    value: 'Toluene'
  }, {
    label: 'Water',
    value: 'Water'
  }, {
    label: 'CDCl3',
    value: 'CDCl3'
  }, {
    label: 'MeOD-d4',
    value: 'MeOD-d4'
  }, {
    label: 'C6D6',
    value: 'C6D6'
  }, {
    label: 'D2O',
    value: 'D2O'
  }];


export const defaultMultiSolventsSmilesOptions = [{
  label: 'Acetic acid',
  value: { external_label: 'Acetic acid',
           smiles: 'CC(O)=O',
           density: 1.05 }
  }, {
  label: 'Acetone',
  value: { external_label: 'Acetone',
           smiles: 'CC(C)=O',
           density: 0.79 }
  }, {
  label: 'Acetonitrile',
  value: { external_label: 'Acetonitrile',
           smiles: 'CC#N',
           density: 0.78 }
  }, {
  label: 'Argon',
  value: { external_label: 'Ar',
           smiles: '[Ar]',
          density: 0.001633 }
  }, {
  label: 'Benzene',
  value: { external_label: 'Benzene',
           smiles: 'C1=CC=CC=C1',
           density: 0.88 }
  }, {
  label: 'n-Butanol',
  value: { external_label: 'n-Butanol',
           smiles: 'CCCCO',
           density: 0.81 }
  }, {
  label: 'Carbon tetrachloride (CCl4)',
  value: { external_label: 'CCl4',
           smiles: 'ClC(Cl)(Cl)Cl',
           density: 1.594 }
  }, {
  label: 'Chloroform',
  value: { external_label: 'Chloroform',
           smiles: 'ClC(Cl)Cl',
           density: 1.48 }
  }, {
  label: 'Cyclohexane',
  value: { external_label: 'Cyclohexane',
           smiles: 'C1CCCCC1',
           density: 0.78 }
  }, {
  label: 'Diethyl ether',
  value: { external_label: 'Diethyl ether',
           smiles: 'CCOCC',
           density: 0.71 }
  }, {
  label: 'Dimethyl sulfoxide (DMSO)',
  value: { external_label: 'DMSO',
           smiles: 'CS(C)=O',
           density: 1.10 }
  }, {
  label: 'Dimethylformamide (DMF)',
  value: { external_label: 'DMF',
           smiles: 'CN(C)C=O',
           density: 0.95 }
  }, {
  label: '1,4-Dioxane',
  value: { external_label: '1,4-Dioxane',
           smiles: 'C1COCCO1',
           density: 1.03 }
  }, {
  label: 'Ethanol',
  value: { external_label: 'Ethanol',
           smiles: 'OCC',
           density: 0.789 }
  }, {
  label: 'Ethyl acetate',
  value: { external_label: 'Ethyl acetate',
           smiles: 'CC(OCC)=O',
           density: 0.894 }
  }, {
  label: 'Isopropanol',
  value: { external_label: 'Isopropanol',
           smiles: 'CC(O)C',
           density: 0.78 }
  }, {
  label: 'Methanol',
  value: { external_label: 'Methanol',
           smiles: 'CO',
           density: 0.79 }
  }, {
  label: 'Methylene chloride (DCM)',
  value: { external_label: 'DCM',
           smiles: 'ClCCl',
           density: 1.33 }
  }, {
  label: 'Methyl tert-butyl ether (MTBE)',
  value: { external_label: 'MTBE',
           smiles: 'O(C(C)(C)C)C',
           density: 0.74 }
  }, {
  label: 'N2',
  value: { external_label: 'N2',
           smiles: 'N#N',
           density: 0.00125 }
  }, {
  label: 'n-Hexane',
  value: { external_label: 'n-Hexane',
           smiles: 'CCCCCC',
           density: 0.66 }
  }, {
  label: 'N-Methyl-2-pyrrolidone (NMP)',
  value: { external_label: 'NMP',
           smiles: 'O=C1CCCN1C',
           density: 1.03 }
  }, {
  label: 'Pentane',
  value: { external_label: 'Pentane',
           smiles: 'CCCCC',
           density: 0.63 }
  }, {
  label: 'Pyridine',
  value: { external_label: 'Pyridine',
           smiles: 'C1=CC=NC=C1',
           density: 0.98 }
  }, {
  label: 'Tetrahydrofuran (THF)',
  value: { external_label: 'THF',
           smiles: 'C1CCCO1',
           density: 0.889 }
  }, {
  label: 'Toluene',
  value: { external_label: 'Toluene',
           smiles: 'CC1=CC=CC=C1',
           density: 0.87 }
  }, {
  label: 'Water',
  value: { external_label: 'Water',
           smiles: '[H]O[H]',
           density: 1.00 }
  }, {
  label: 'CDCl3',
  value: { external_label: 'CDCl3',
           smiles: '[2H]C(Cl)(Cl)Cl',
           density: 1.500 }
  }, {
  label: 'MeOD-d4',
  value: { external_label: 'MeOD-d4',
           smiles: '[2H]OC([2H])([2H])[2H]',
           density: 0.888 }
  }, {
  label: 'C6D6',
  value: { external_label: 'C6D6',
           smiles: '[2H]c1c([2H])c([2H])c([2H])c([2H])c1[2H]',
           density: 0.950 }
  }, {
  label: 'D2O',
  value: { external_label: 'D2O',
           smiles: '[2H]O[2H]',
           density: 1.107 }
  }, {
    label: 'Cyclopentane',
    value: { external_label: 'Cyclopentane',
             smiles: 'C1CCCC1',
             density: 0.74 }
  }, {
    label: 'Nitromethane',
    value: { external_label: 'Nitromethane',
             smiles: 'C[N+]([O-])=O',
             density: 1.14 }
  }, {
    label: 'Formic acid',
    value: { external_label: 'Formic acid',
             smiles: 'O=CO',
             density: 1.22 }
  }, {
    label: 'n-octanol',
    value: { external_label: 'n-octanol',
             smiles: 'CCCCCCCCO',
             density: 0.817 }
  }, {
    label: 'n-propanol',
    value: { external_label: 'n-propanol',
             smiles: 'CCCO	',
             density: 0.8 }
  }, {
    label: 'Dimethylacetamide',
    value: { external_label: 'Dimethylacetamide',
             smiles: 'CC(N(C)C)=O',
             density: 0.94 }
  }, {
    label: '1,2-xylene',
    value: { external_label: '1,2-xylene',
             smiles: 'CC1=CC=CC=C1C',
             density: 0.8755 }
  }, {
    label: '1,3-xylene',
    value: { external_label: '1,3-xylene',
             smiles: 'CC1=CC=CC(C)=C1',
             density: 0.8598 }
  }, {
    label: '1,4-xylene',
    value: { external_label: '1,4-xylene',
             smiles: 'CC1=CC=C(C)C=C1',
             density: 0.8565 }
  }, {
    label: 'Sulfolane',
    value: { external_label: 'Sulfolane',
             smiles: 'O=S1(CCCC1)=O',
             density: 1.261 }
  }, {
    label: '2-methoxy ethanol',
    value: { external_label: '2-methoxy ethanol',
             smiles: 'OCCOC',
             density: 0.97 }
  }, {
    label: 'Carbon tribromide',
    value: { external_label: 'Carbon tribromide',
             smiles: 'BrC(Br)([H])Br',
             density: 2.89 }
  }, {
    label: 'Cyclopentylmethylether',
    value: { external_label: 'Cyclopentylmethylether',
             smiles: 'COC1CCCC1',
             density: 0.86 }
  }, {
    label: '1,2-Dichloroethane',
    value: { external_label: '1,2-Dichloroethane',
             smiles: 'ClCCCl',
             density: 1.25 }
  }
];


export const purificationOptions = [{
  label: 'Flash-Chromatography',
  value: 'Flash-Chromatography'
}, {
  label: 'TLC',
  value: 'TLC'
}, {
  label: 'HPLC',
  value: 'HPLC'
}, {
  label: 'Extraction',
  value: 'Extraction'
}, {
  label: 'Distillation',
  value: 'Distillation'
}, {
  label: 'Sublimation',
  value: 'Sublimation'
}, {
  label: 'Crystallisation',
  value: 'Crystallisation'
}, {
  label: 'Recrystallisation',
  value: 'Recrystallisation'
}, {
  label: 'Dialysis',
  value: 'Dialysis'
}];

export const statusOptions = [{
  label: 'Planned',
  value: 'Planned',
}, {
  label: 'Running',
  value: 'Running',
}, {
  label: 'Done',
  value: 'Done',
}, {
  label: 'Analyses Pending',
  value: 'Analyses Pending',
}, {
  label: 'Successful',
  value: 'Successful',
}, {
  label: 'Not Successful',
  value: 'Not Successful',
}];


export const dangerousProductsOptions = [{
  label: 'Causes cancer',
  value: 'Causes cancer'
}, {
  label: 'Mutagenic',
  value: 'Mutagenic'
}, {
  label: 'Damage to environment',
  value: 'Damage to environment'
}, {
  label: 'Explosive (Class 1)',
  value: 'Explosive (Class 1)'
}, {
  label: 'Pressure (Class 2)',
  value: 'Pressure (Class 2)'
}, {
  label: 'Flammable liquid (Class 3)',
  value: 'Flammable liquid (Class 3)'
}, {
  label: 'Flammable solid (Class 4.1)',
  value: 'Flammable solid (Class 4.1)'
}, {
  label: 'Self-flammable solid (Class 4.2)',
  value: 'Self-flammable solid (Class 4.2)'
}, {
  label: 'Flammable/contact with water (Class 4.3)',
  value: 'Flammable/contact with water (Class 4.3)'
}, {
  label: 'Oxidizing (Class 5.1)',
  value: 'Oxidizing (Class 5.1)'
}, {
  label: 'Peroxides (Class 5.2)',
  value: 'Peroxides (Class 5.2)'
}, {
  label: 'Toxic and very toxic (Class 6.1)',
  value: 'Toxic and very toxic (Class 6.1)'
}, {
  label: 'Infective (Class 6.2)',
  value: 'Infective (Class 6.2)'
}, {
  label: 'Radioactive (Class 7)',
  value: 'Radioactive (Class 7)'
}, {
  label: 'Corrosive (Class 8)',
  value: 'Corrosive (Class 8)'
}, {
  label: 'Diverse (Class 9)',
  value: 'Diverse (Class 9)'
}];



export const confirmOptions = [{
  label: "Confirmed",
  value: "Confirmed"
},{
  label: "Unconfirmed",
  value: "Unconfirmed"
}];

export const kindOptions = [{
  label: "1H NMR",
  value: "1H NMR"
},{
  label: "13C NMR",
  value: "13C NMR"
},{
  label: "Mass",
  value: "Mass"
},{
  label: "IR",
  value: "IR"
},{
  label: "EA",
  value: "EA"
},{
  label: "GCMS",
  value: "GCMS"
},{
  label: "HPLC",
  value: "HPLC"
},{
  label: "TLC",
  value: "TLC"
},{
  label: "Crystal-Structure",
  value: "Crystal-Structure"
},{
  label: "Others",
  value: "Others"
}];

export const rolesOptions = [{
  label: 'General Procedure',
  value: 'gp',
  icon: 'fa-home',
  bsStyle: 'c-bs-primary',
}, {
  label: 'Parts of GP',
  value: 'parts',
  icon: 'fa-bookmark',
  bsStyle: 'c-bs-success',
}, {
  label: 'Single',
  value: 'single',
  icon: 'fa-asterisk',
  bsStyle: 'c-bs-danger',
}];

export const conditionsOptions = [{
  label: 'UV',
  value: 'UV'
}, {
  label: 'microwave',
  value: 'microwave'
}, {
  label: 'ultrasound',
  value: 'ultrasound'
}, {
  label: 'visible light',
  value: 'visible light'
}, {
  label: 'pH = value',
  value: 'pH = value'
}, {
  label: 'pH < value',
  value: 'pH &lt; value' /* &lt; renders < in HTML*/
}, {
  label: 'pH > value',
  value: 'pH &gt; value' /* &gt; renders > in HTML*/
}];